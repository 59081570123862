import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Projects from "@/views/Projects.vue";
import Writings from "@/views/Writings.vue";
import Settings from "@/views/Settings.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: { foregroundColor: "#F9C5C2" }
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    props: { foregroundColor: "#C43F66"}

  },
  {
    path: "/writings",
    name: "Writings",
    component: Writings,
    props: { foregroundColor: "#16737E" }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    props: { foregroundColor: "#C94235" }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;