<template>
  <main>
    <span v-show="showTitle" data-aos="zoom-out" data-aos-duration="2000">Projects</span>
  </main>
</template>
<script>
import { ref } from "vue";
import { showIntro } from "../utils";
export default {
  name: "Projects",
  props: ["foregroundColor"],
  setup(props) {
    const showTitle = ref(true);
    showIntro(showTitle);
    return {
      showTitle,
    };
  },
};
</script>
<style scoped>
main {
  background-color: v-bind(foregroundColor);
}
</style>
