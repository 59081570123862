<template>
  <div class="leftLogo">
    <div>
      <h3>Tommaso Bellini</h3>
      <h4>Freelance Developer</h4>
    </div>
  </div>
  <div id="nav-wrapper">
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      :value="selected"
      :foregroundColor="foregroundColor"
      @update="getButton"
    />
  </div>

  <router-view />
</template>
<script>
import { ref, watch, watchEffect, computed, reactive, onMounted } from "vue";
import VueBottomNavigation from "bottom-navigation-vue";
import { useRouter, useRoute } from "vue-router";
import Constants from "../constants";

export default {
  name: "Navbar",
  components: { VueBottomNavigation },
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name;
    });
    const options = ref([
      {
        id: 1,
        icon: "fas fa-rocket",
        title: "Home",
        path: { name: "Home" },
        foregroundColor: Constants.PINK_PANTONE,
      },
      {
        id: 2,
        icon: "fas fa-business-time",
        title: "Projects",
        path: { name: "Projects" },
        foregroundColor: Constants.FUCHSIA_PANTONE,
      },
      {
        id: 3,
        icon: "fas fa-pen-fancy",
        title: "Writings",
        path: { name: "Writings" },
        foregroundColor: Constants.BLUE_PANTONE,
      },
      {
        id: 4,
        icon: "fas fa-hammer",
        path: { name: "Settings" },
        title: "Settings",
        foregroundColor: Constants.RED_PANTONE,
      },
    ]);
    const selected = ref(1);

    const getCurrentSelected = () => {
      console.log(options.value);
      for (let i = 0; i > options.value.length; i++) {
        if (options.value[i].path.name === currentRoute.value) {
          console.log(selected.value);
          selected.value = options.value[i].id;
        }
      }
    };
    getCurrentSelected();
    const foregroundColor = ref(options.value[selected.value - 1].foregroundColor);
    const getButton = (ev) => {
      foregroundColor.value = options.value[ev - 1].foregroundColor;
    };

    return {
      selected,
      options,
      foregroundColor,
      getButton,
      currentRoute,
    };
  },
};
</script>
<style scoped>
h3,
h4 {
  margin: 5px;
}
h4 {
  font-weight: lighter;
}
.leftLogo {
  position: absolute;
  z-index: 999;
  color: white;
  margin: 10px;
  text-transform: uppercase;
}
.leftLogo:hover {
  cursor: pointer;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
  .btn-containrt_foreground {
    width: 50%;
  }
  #nav-wrapper {
    display: flex;
    justify-content: center;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
  .btn-containrt_foreground {
    width: 50%;
  }
  #nav-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
