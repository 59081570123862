<template>
  <div class="centerSlogan">
    <div>
      <h2>The two parts of my self</h2>
    </div>
  </div>
  <main>
    <span v-show="showTitle" data-aos="zoom-out" data-aos-duration="2000">Home</span>
  </main>
  <div class="splitview skewed">
    <div class="panel bottom">
      <div class="content">
        <div class="description">
          <h1>Love for my job.</h1>
          <p>This is how the image looks like before applying a duotone effect.</p>
        </div>

        <img src="../assets/fullstack.png" alt="Original" />
      </div>
    </div>

    <div class="panel top">
      <div class="content">
        <div class="description">
          <h1>Need some coffee.</h1>
          <p>This is how the image looks like after applying a duotone effect.</p>
        </div>

        <img src="../assets/sleepy.jpg" alt="Original" class="imageSleepy" />
      </div>
    </div>

    <div class="handle"></div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { showIntro } from "../utils";
import Constants from "../constants";

export default {
  name: "Home",
  props: ["foregroundColor"],
  setup(props) {
    const showTitle = ref(true);
    const PINK_PANTONE = ref(Constants.PINK_PANTONE);
    showIntro(showTitle);
    onMounted(() => {
      console.log("mounted home");
      function work() {
        var parent = document.querySelector(".splitview"),
          topPanel = parent.querySelector(".top"),
          handle = parent.querySelector(".handle"),
          skewHack = 0,
          delta = 0;
        console.log(parent);
        // If the parent has .skewed class, set the skewHack var.
        if (parent.className.indexOf("skewed") != -1) {
          skewHack = 1000;
        }

        parent.addEventListener("mousemove", function (event) {
          console.log("parent");
          // Get the delta between the mouse position and center point.
          delta = (event.clientX - window.innerWidth / 2) * 0.5;

          // Move the handle.
          handle.style.left = event.clientX + delta + "px";

          // Adjust the top panel width.
          topPanel.style.width = event.clientX + skewHack + delta + "px";
        });
      }
      if (document.readyState == "loading") {
        // still loading, wait for the event
        document.addEventListener("readystatechange", work());
      } else {
        // DOM is ready!
        work();
      }
    });
    return {
      showTitle,
    };
  },
};
</script>
<style scoped>
main {
  background-color: v-bind(foregroundColor);
  position: absolute;
  z-index: 1;
}
* {
  box-sizing: border-box;
}

/* Panels. */
.splitview {
  position: relative;
  width: 100%;
  min-height: 45vw;
  overflow: hidden;
}

.panel {
  position: absolute;
  width: 100vw;
  min-height: 45vw;
  overflow: hidden;
}

.panel .content {
  position: absolute;
  width: 100vw;
  min-height: 45vw;
  color: #fff;
}

.panel .description {
  width: 25%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.panel img {
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.15);
  width: 35%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageSleepy {
  width: 41% !important;
}
.bottom {
  background-color: v-bind(PINK_PALETTE);
  z-index: 1;
}

.bottom .description {
  right: 5%;
}

.top {
  background-color: rgb(235, 212, 196);
  z-index: 2;
  width: 50vw;

  /*-webkit-clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);*/
}

.top .description {
  left: 5%;
}

/* Handle. */
.handle {
  height: 100%;
  position: absolute;
  display: block;
  background-color: rgb(253, 171, 0);
  width: 5px;
  top: 0;
  left: 50%;
  z-index: 3;
}

/* Skewed. */
.skewed .handle {
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content {
  transform: skew(30deg);
  margin-left: 1000px;
}

/* Responsive. */
@media (max-width: 900px) {
  body {
    font-size: 75%;
  }
}

.centerSlogan {
  position: absolute;
  z-index: 999;
  color: white;
  right: 0;
  left: 0;
  width: 100vw;
  text-transform: uppercase;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 320px) and (max-width: 480px) {
  /* CSS */
  .splitview {
    height: 100vh;
  }
  .panel {
    height: 100vh;
  }
  .panel img {
    top: 25%;
    width: 50%;
  }
  .imageSleepy {
    width: 58% !important;
  }
  .panel .description {
    bottom: 25%;
  }
  .content {
    height: 100vh;
  }

  .centerSlogan {
    bottom: 10%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
  .panel .description {
    bottom: 50%;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
  .panel .description {
    bottom: 50%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
}
</style>
