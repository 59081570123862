const PINK_PANTONE = '#F9C5C2';
const FUCHSIA_PANTONE = '#C43F66';
const RED_PANTONE = '#C94235';
const BLUE_PANTONE = '#16737E';

export default {
    PINK_PANTONE: PINK_PANTONE,
    FUCHSIA_PANTONE: FUCHSIA_PANTONE,
    RED_PANTONE: RED_PANTONE,
    BLUE_PANTONE: BLUE_PANTONE,
}
